@import-normalize;

/**
 * Brand colours
 */
$brand-primary: #3a0ca3;
$brand-secondary: #7728d1;
$brand-tertiary: #9c56ec;
$brand-accent: #4cc9f0;

/**
 * Text, bg and basic colours
 */
$text-primary: #1b1b1b;
$text-secondary: #e9e9e9;
$text-alert: #f72585;

$grey-primary: #302b2b;
$grey-secondary: #5a5656;
$grey-light: #e2e2e2;

/**
 * Fonts and any other base settings
 */

body {
   font-family: 'Open Sans', serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: 'Ubuntu', sans-serif;
}
.visually-hidden {
   clip: rect(0 0 0 0);
   clip-path: inset(50%);
   height: 1px;
   width: 1px;
   position: absolute;
   white-space: nowrap;
}
.status {
   color: #198754;
}
.error {
   color: red;
}

/**
 * Utility classes for layout/all components
 */
$breakpoints: (
   'phone': 767px,
   'tablet': 992px,
   'laptop': 1200px,
   'desktop': 1440px,
) !default;

@mixin screen-size($breakpoint) {
   @if map-has-key($breakpoints, $breakpoint) {
      @media (min-width: map-get($breakpoints, $breakpoint )) {
         @content;
      }
   } @else {
      @warn "The screen size given can not be found. Please use one of: #{map-keys($breakpoints)}";
   }
}

.wrapper {
   width: 95%;

   @include screen-size('desktop') {
      padding: 0 0.5rem;
      width: 1440px;
   }

   // max-width: 1440px;
   // width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

@import './components/header/header.scss';
@import './components/leaderboard/leaderboard.scss';
@import './components/profile/profile.scss';
@import './components/loader/loader.scss';
