.header {
   background-image: linear-gradient(
      to right,
      $brand-primary,
      $brand-secondary,
      $brand-tertiary
   );
   padding: 1em 1em;
   display: flex;
   flex-direction: column;
   justify-content: center;

   @include screen-size('tablet') {
      padding: 1em 2em;
   }
   @include screen-size('laptop') {
      align-items: center;
   }
   @include screen-size('desktop') {
      padding: 1em 3em;
   }

   .wrapper {
      @include screen-size('desktop') {
         flex-direction: row;
      }
   }

   h1 {
      color: $text-secondary;
      margin-bottom: 1rem;
      font-size: 1.5em;
      @include screen-size('desktop') {
         font-size: 2.5em;
         margin-bottom: 0;
      }
   }

   nav {
      display: flex;
      flex-grow: 1;
      align-self: flex-start;
      justify-content: flex-end;
      position: relative;

      @include screen-size('tablet') {
         align-self: flex-end;
         justify-content: flex-end;
      }

      ul {
         display: flex;
         flex-direction: row;
         list-style: none;
         font-size: 1rem;
         margin-bottom: 0;
         padding: 0;

         @include screen-size('tablet') {
            font-size: 1.25rem;
         }

         li button,
         li a {
            padding: 0 15px 0 0;
            color: $text-secondary;
            background-color: transparent;
            border: none;
            text-transform: uppercase;
            text-decoration: none;
         }
         li button:hover,
         li a:hover {
            color: $brand-accent;
         }
      }

      .dropdown {
         position: relative;
         display: inline-block;
      }
      .dropdown-content {
         position: absolute;
         background-color: $grey-light;
         margin-top: 10px;
         padding: 10px 0;
         right: 0;
         box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
         z-index: 1;
         display: none;

         a,
         button {
            margin: 5px 0;
            font-size: 1.25rem;
            color: $text-primary;
            padding: 10px 20px;
            display: block;
            text-transform: uppercase;
         }
         a:hover,
         button:hover {
            background-color: $brand-accent;
            text-decoration: none;
         }

         button {
            background-color: transparent;
            border: none;
            text-transform: uppercase;
         }
      }

      .dropdown-content.show {
         display: initial;
      }
   }
}
