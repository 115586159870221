.leaderboards {
   display: flex;
   flex-direction: row;
   justify-content: center;

   .wrapper {
      flex-direction: column;
   }
   .boards {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0;

      @include screen-size('laptop') {
         grid-template-columns: 1fr 1fr;
         grid-gap: 50px;
      }
   }

   .board {
      border: 1px solid $grey-secondary;
      margin: 20px 0;
      display: flex;
      flex-direction: column;

      @include screen-size('laptop') {
         margin: 10px 0;
      }

      .title,
      .footer {
         background-image: linear-gradient(
            to right,
            $brand-primary,
            $brand-tertiary
         );

         h3 {
            font-size: 1.4rem;
            color: $text-secondary;
            padding: 10px;
         }
      }
      .footer {
         margin-top: 1rem;
         padding: 0.25rem 0.25rem;
         text-align: right;

         a {
            font-size: 1rem;
            color: $text-secondary;

            svg {
               height: 24px;
               width: 24px;
               margin-left: 0.5rem;
            }
         }
      }

      .title:hover {
         background-image: linear-gradient(
            to right,
            $brand-primary,
            $brand-secondary
         );
         a {
            text-decoration: none;
         }
         h3 {
            color: $brand-accent;
         }
      }

      .info {
         padding: 10px;
         font-size: 1.3rem;
         font-style: italic;
      }

      .info.small {
         font-size: 0.9rem;
         flex-grow: 1;
      }

      .records {
         font-size: 1.2rem;

         .record {
            display: grid;
            grid-template-columns: 1fr auto;
            margin-bottom: 3px;
            padding: 0 10px 2px 10px;

            &.odd {
               background-color: rgba($brand-primary, 0.1);
            }

            .controls {
               display: flex;
               flex-direction: row;
            }
            .increment,
            .decrement {
               background-color: transparent;
               border: 2px solid rgba($brand-primary, 0.1);
               color: rgba($brand-primary, 0.1);
               margin-right: 0.2rem;
               @include screen-size('tablet') {
                  margin-right: 0.5rem;
               }

               svg {
                  height: 14px;
                  width: 14px;
               }
            }
            .buttons:hover .increment,
            .buttons:hover .decrement {
               border: 2px solid $brand-primary;
               color: $brand-primary;
            }
            .increment:active,
            .decrement:active {
               background-color: rgba($brand-primary, 0.3);
            }
            .decrement {
               margin-right: 0.75rem;
               @include screen-size('tablet') {
                  margin-right: 1.5rem;
               }
            }
         }
      }
   }
}

.leaderboard {
   display: flex;
   flex-direction: row;
   justify-content: center;

   .wrapper {
      flex-direction: column;

      @include screen-size('laptop') {
         width: 1008px;
      }
   }

   .board {
      width: 100%;

      .title {
         background-image: linear-gradient(
            to right,
            $brand-primary,
            $brand-secondary
         );
         color: $text-secondary;
         padding: 10px;
         margin: 0 0 20px;
         grid-column: 1/3;
         display: grid;
         grid-template-columns: 1fr auto;

         h2 {
            font-size: 1.45rem;
         }
      }

      .entry {
         display: grid;
         grid-template-columns: 1fr auto;
         padding: 5px 10px;
         font-size: 1.1rem;

         &.odd {
            background-color: rgba($brand-primary, 0.1);
         }

         .controls {
            display: flex;
            flex-direction: row;
         }
         .increment,
         .decrement {
            background-color: transparent;
            border: 2px solid rgba($brand-primary, 0.1);
            color: rgba($brand-primary, 0.1);
            margin-right: 0.5rem;

            svg {
               height: 14px;
               width: 14px;
            }
         }
         .buttons:hover .increment,
         .buttons:hover .decrement {
            border: 2px solid $brand-primary;
            color: $brand-primary;
         }
         .increment:active,
         .decrement:active {
            background-color: rgba($brand-primary, 0.3);
         }
         .decrement {
            margin-right: 1.5rem;
         }
      }
   }

   .create-new {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      margin-top: 3rem;

      h3 {
         grid-column: 1/5;
         margin-bottom: 1rem;
      }

      .form {
         width: 100%;
      }

      .form form {
         display: grid;
         grid-template-columns: 1fr;
         width: 100%;
         @include screen-size('laptop') {
            grid-template-columns: 1fr auto 1fr auto;
         }

         select,
         option {
            font-size: 1.25rem;
            padding: 7px 0 9px;
         }
         button {
            background-color: $brand-accent;
            border: 0;
            color: $text-primary;
            font-family: 'ubuntu';
            font-size: 1.25rem;
            padding: 7px 35px 9px;
            margin-top: 1rem;
            @include screen-size('laptop') {
               margin-top: 0;
               margin: 0 0 0 20px;
            }
         }

         .action {
            padding: 0 15px 0px 15px;
            font-size: 1.25rem;
            height: 100%;
            display: flex;
            align-items: center;
         }
         .action p {
            margin: 1rem 0;
            @include screen-size('laptop') {
               margin: 0;
            }
         }
      }
   }
   h2 {
   }
   .info {
      padding-bottom: 10px;
      font-size: 1.3rem;
      font-style: italic;
   }

   .info.small {
      font-size: 0.9rem;
      flex-grow: 1;
      margin-top: 1.5rem;
   }
}

.leaderboard-list-item {
   display: grid;
   grid-template-columns: 1fr;
   @include screen-size('laptop') {
      grid-template-columns: 1fr 3fr auto;
   }
   margin-top: 5px;

   div {
      padding: 7px;
   }
   .controls {
      padding: 7px;
      color: red;
      cursor: pointer;
   }

   &.odd {
      background-color: rgba($brand-primary, 0.1);
   }

   button {
      background-color: $brand-primary;
      color: $text-secondary;
      border: none;
      font-size: 1rem;
      padding: 5px 15px;
   }
}

.error404 {
   display: flex;
   justify-content: center;

   .wrapper {
      flex-direction: column;
   }
}
