.loader {
   display: flex;
   justify-content: center;

   .wrapper {
      flex-direction: row;
   }

   .spinner {
      display: inline-block;
      position: relative;
      width: 160px;
      height: 160px;
      margin-top: 5rem;

      &.small {
         width: 40px;
         height: 40px;
         margin-top: 0;
      }
   }

   .large:after {
      content: ' ';
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 8px;
      box-sizing: border-box;
      border: 64px solid $brand-primary;
      border-color: $brand-primary transparent $brand-accent transparent;
      animation: spinner 1.2s infinite;
   }
   .small:after {
      content: ' ';
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 8px;
      box-sizing: border-box;

      border: 20px solid $brand-primary;
      border-color: $brand-primary transparent $brand-accent transparent;
      animation: spinner 1.2s infinite;
   }
   @keyframes spinner {
      0% {
         transform: rotate(0);
         animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      50% {
         transform: rotate(900deg);
         animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      100% {
         transform: rotate(1800deg);
      }
   }
}
